.tooltip-component-module__0XEmZa__tooltip {
  z-index: 100;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: break-word;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  max-width: 250px;
  padding: .25rem .5rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: fixed;
}

.tooltip-component-module__0XEmZa__tooltip .tooltip-component-module__0XEmZa__caret-up {
  transform-origin: center;
  background-image: linear-gradient(45deg, #3e3e3e 50%, rgba(255, 255, 255, 0) 50%);
  width: 8px;
  height: 8px;
  position: absolute;
  transform: rotate(135deg);
}

.dropdown-shared-style-module__c4x9_W__dropdown {
  white-space: nowrap;
  display: inline-block;
  position: relative;
}

.dropdown-shared-style-module__c4x9_W__dropdown button {
  height: 38px;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropbtn {
  cursor: pointer;
  background-color: #cecece;
  border: 1px solid #b6b6b6;
  padding: 0;
  font-size: 1rem;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropbtn .dropdown-shared-style-module__c4x9_W__button-prefix {
  background-color: #b6b6b6;
  padding: .2rem .4rem;
  font-size: 1rem;
  line-height: 1.5;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropbtn .dropdown-shared-style-module__c4x9_W__split-button {
  vertical-align: top;
  padding: .2rem;
  font-weight: bold;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropbtn .dropdown-shared-style-module__c4x9_W__selected-value {
  padding: .2rem;
  font-weight: bold;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropdown-content {
  z-index: 1000;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #f1f1f1;
  border: 1px solid rgba(0, 0, 0, .15);
  min-width: 160px;
  max-height: 380px;
  margin-top: 4px;
  display: none;
  position: absolute;
  right: 0;
  overflow: auto;
  box-shadow: 0 8px 1rem rgba(0, 0, 0, .2);
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropdown-content.dropdown-shared-style-module__c4x9_W__show {
  display: block;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__left-aligned {
  left: 0;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__right-aligned {
  right: 0;
}

.dropdown-shared-style-module__c4x9_W__dropdown ul {
  margin: 0;
  padding: 0;
}

.dropdown-shared-style-module__c4x9_W__dropdown ul li {
  cursor: pointer;
  margin-bottom: 1px;
  padding: 5px 10px;
}

.dropdown-shared-style-module__c4x9_W__dropdown ul li .dropdown-shared-style-module__c4x9_W__tooltip {
  margin-left: 5px;
}

.dropdown-shared-style-module__c4x9_W__dropdown ul li:nth-child(2n) {
  background: #ececec;
}

.dropdown-shared-style-module__c4x9_W__dropdown ul li.dropdown-shared-style-module__c4x9_W__active {
  color: #fff;
  background-color: #636363;
}

.dropdown-shared-style-module__c4x9_W__dropdown ul li:hover {
  background-color: #a1a1a1;
}

.dropdown-shared-style-module__c4x9_W__dropdown {
  width: 100%;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropdown-btn-group {
  justify-content: space-between;
  display: flex;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropdown-btn-group .dropdown-shared-style-module__c4x9_W__value {
  text-align: left;
  flex-grow: 1;
}

.dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropdown-btn-group label, .dropdown-shared-style-module__c4x9_W__dropdown .dropdown-shared-style-module__c4x9_W__dropdown-btn-group .dropdown-shared-style-module__c4x9_W__caret {
  flex-grow: unset;
}

